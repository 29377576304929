<template>
  <v-dialog
    persistent
    v-model="dialogDetailUser"
    :width="`${screenWidth}%`"
  >
    <div class="container-dialog">
      <close-dialog-component @closeDialog="closeDialog" />
      <p class="text-title mon-bold">{{ texts.users.dialogDetailUser.textTitle }}</p>
      <div>
        <p class="text-name mon-semibold">{{ userName }} {{ userLastName }}</p>
      </div>
      <div class="content-db display-flex align-items-center justify-content-center width-100">
        <div class="display-flex align-items-center mr-3 ml-3">
          <v-icon
            color="#000000"
            size="17px"
          >
            mdi-account
          </v-icon>
          <p class="text-position mon-regular ml-1">{{ userPosition }}</p>
        </div>
        <div class="remove-mrg display-flex align-items-center mr-3 ml-3">
          <v-icon
            color="#000000"
            size="17px"
          >
            mdi-phone-outline
          </v-icon>
          <p class="text-phone-number mon-regular ml-1">{{ userPhoneNumber }}</p>
        </div>
        <div class="display-flex align-items-center mr-3 ml-3">
          <v-icon
            color="#000000"
            size="17px"
          >
            mdi-email-outline
          </v-icon>
          <p class="text-email mon-regular ml-1">{{ userEmail }}</p>
        </div>
        <div class="display-flex align-items-center mr-3 ml-3">
          <v-icon
            :color="userStatus == false ? '#D8686A' : '#47B900'"
            size="17px"
          >
            mdi-check-decagram
          </v-icon>
          <p :class="userStatus == false ? 'text-inactive mon-bold ml-1' : 'text-active mon-bold ml-1'">
            {{ 
            (userStatus == false)
            ? texts.users.dialogDetailUser.textInactive
            : texts.users.dialogDetailUser.textActive
           }}
          </p>
        </div>
      </div>
      <div
        v-if="isResponsive"
        class="mt-9"
      >
        <v-btn
          @click="editUser"
          elevation="0"
          class="button-primary mon-bold"
          style="width: 100%;"
        >
          {{ texts.users.dialogDetailUser.textButtonModify }}
        </v-btn>
        <v-btn
          @click="changeStatus"
          elevation="0"
          class="button-secondary mon-regular mt-5"
          :loading="bLoading"
          style="width: 100%;"
        >
          {{ texts.users.dialogDetailUser.textButtonChangeState }}
        </v-btn>
      </div>
      <div
        v-else
        class="content-db display-flex align-items-center mt-9"
      >
        <v-spacer></v-spacer>
        <v-btn
          @click="changeStatus"
          elevation="0"
          class="button-secondary mon-regular mr-2"
          :loading="bLoading"
        >
          {{ texts.users.dialogDetailUser.textButtonChangeState }}
        </v-btn>
        <v-btn
          @click="editUser"
          elevation="0"
          class="button-primary mon-bold"
        >
          {{ texts.users.dialogDetailUser.textButtonModify }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "UsersDialogDetailUserLayout",
  props: {
    texts: {
      type: Object,
    },
  },
  data() {
    return {
      userName: "",
      userPosition: "",
      userPhoneNumber: "",
      userEmail: "",
      userLastName: "",
      userStatus: false,
      bLoading: false,
      screenWidth: 0,
      isResponsive: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    changeStatus: function () {
      this.bLoading = true;

      const payload = {
        bPlatformAccess: !this.userStatus,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$store.state.sEnterpriseId}/employees/${this.dialogDetailUserArr.sVendorId}`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;

          this.mixSuccess(response.data.message);
          this.closeDialog();
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    editUser: function () {
      this.$store.commit("setDialogEditUser", {
        arr: this.dialogDetailUserArr,
        active: true,
      });
      this.closeDialog();
    },
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 60;
      } else {
        this.screenWidth = 100;
      }

      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    closeDialog: function () {
      this.$store.commit("setDialogDetailUser", {
        active: false,
        arr: [],
      });
    },
  },
  computed: {
    // return create contact dialog state
    dialogDetailUser: function () {
      return this.$store.state.dialogDetailUser;
    },
    selectLanguage: function () {
      return this.$store.state.language;
    },
    dialogDetailUserArr: function () {
      return this.$store.state.dialogDetailUserArr;
    },
  },
  watch: {
    dialogDetailUser() {
      if (this.dialogDetailUser) {
        this.userName = this.dialogDetailUserArr.sName;
        this.userLastName = this.dialogDetailUserArr.sLastname;
        this.userPosition = this.dialogDetailUserArr.sPositionName;
        this.userPhoneNumber = this.dialogDetailUserArr.sPhoneNumber;
        this.userEmail = this.dialogDetailUserArr.sEmail;
        this.userStatus = this.dialogDetailUserArr.bPlatformAccess;
        // this.texts = FILE.users[this.selectLanguage];
      }
    },
    selectLanguage: function () {
      if (this.selectLanguage) {
        // this.texts = FILE.users[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.text-inactive {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #d8686a;
  opacity: 1;
  margin-bottom: 0px;
}

.text-active {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #47b923;
  opacity: 1;
  margin-bottom: 0px;
}

.icon-close {
  position: absolute;
  right: 10px;
  top: 10px;
}

.text-position,
.text-phone-number,
.text-email {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.text-name {
  text-align: center;
  font-size: 20px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
}

.container-dialog {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  opacity: 1;
  padding: 25px;
  position: relative;
}

.text-title {
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-size: 20px;
}

@media (max-width: 600px) {
  .content-db {
    display: block;
  }
  .content-hidden {
    display: none;
  }
  .remove-mrg {
    margin: 0px !important;
  }
  .text-name {
    text-align: left;
  }
  .button-add,
  .button-cancel {
    width: 100%;
    margin-top: 10px;
  }
}
</style>